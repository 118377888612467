<script setup>
import { computed, onMounted, ref, getCurrentInstance } from 'vue'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import { useStore } from 'vuex'
import _ from 'lodash';
import StreamService from '@/services/StreamService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { useNotification } from "@kyvg/vue3-notification"
import FormButton from '../../components/Atoms/FormButton.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import IconBase from '@/components/icon/IconBase.vue';
import IconCross from '../../components/icon/IconCross.vue';
import Alert from '../../components/Atoms/Alert.vue';

const { notify } = useNotification()

const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const tooltipMessage = `Only one webhook allowed for each stream. <span class="underline" onclick="Intercom('show')">Contact sales</span> to allow more than one.`

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	}
})

const streamId = ref(null)
const webhook = ref({
	name: null,
	url: null,
	enabled: false
})

const userWebhook = ref({
	name: null,
	url: null,
	enabled: false
})
const deleteTitle = ref(null)
const isAccess = ref(false)

const edited = computed(() => {
	return !_.isEqual(webhook.value, userWebhook.value)
})
onMounted(async () => {
	const allowedPlanIds = [
		'6361ecd609950a6a642540b5', // Multistream Business
		'6361ecd609950a6a642540b6', // Multistream Business - Yearly
		'6361ecd609950a6a642540be', // All in One - Business
		'6361ecd609950a6a642540bd', // All in One - Business Yearly
		'6361ecd609950a6a642540bb', // All in One - Enterprise
		'6361ecd609950a6a642540bc', // All in One - Enterprise Yearly
		'643502dd40771d569f1e98f0', // Ultra Monthly
		'6435044340771d569f1e98fa', // Ultra Yearly
		'6629cb18883348e523b6de8d', // Custom package for nuruddin.vali-ext@akdn.org
		'67ee632275708706763130c1', // Custom package for ray@samedayproductions.com
	]
	const subscriptions = store.state.User.subscriptions;
	isAccess.value = subscriptions.some((sub) => allowedPlanIds.indexOf(sub.package._id) !== -1)

	streamId.value = props.stream._id;
	if (props.stream.metadata.webhookEndpoint) { webhook.value = props.stream.metadata.webhookEndpoint }
	userWebhook.value = _.clone(webhook.value)
})
const saveWebhookUrl = async () => {
	try {
		const params = {
			name: webhook.value.name,
			url: webhook.value.url,
			enabled: webhook.value.enabled
		}
		params.url = params.url.trim()
		const res = await StreamService.saveStreamWebhookEndpoints(streamId.value, params)
		webhook.value = res
		userWebhook.value = _.clone(webhook.value)
		notify({ group: 'success', text: 'You successfully added a webhook endpoint' });
	} catch (e) {
		notify({ group: 'error', text: e ? e.message : 'Failed to save webhook URL' })
	}
}
const toggleWebhookUrl = async () => {
	try {
		const params = {
			name: webhook.value.name,
			url: webhook.value.url,
			enabled: !webhook.value.enabled
		}
		const res = await StreamService.saveStreamWebhookEndpoints(streamId.value, params)
		webhook.value = res
		userWebhook.value = _.clone(webhook.value)
	} catch (e) {
		notify({ group: 'error', text: e ? e.message : 'Failed to toggle webhook URL' })
	}
}
const deleteWebhookUrl = () => {
	deleteTitle.value = `Delete \'${webhook.value.name}\' webhook?`
	root.$emit("bv::show::modal", 'modal-confirm-1')
}
const onDeleteWebhookUrl = async () => {
	try {
		await StreamService.deleteStreamWebhookEndpoints(streamId.value)
		webhook.value = {
			name: null,
			url: null,
			enabled: false
		}
		userWebhook.value = _.clone(webhook.value)
	} catch (e) {
		notify({ group: 'error', text: e ? e.message : 'Failed to toggle webhook URL' })
	}
}
</script>

<template>
	<div>
		<div class="flex flex-col text-center" v-if="!isAccess">
				<p class="text-lg !mt-6 !mb-2">You don’t have access to this feature</p>
				<p class="text-sm text-surface-8"><router-link to="/subscribe?category=live" class="hover:no-underline">Upgrade</router-link> or <FormButton
						type="link" customClasses="text-surface-8 underline" onclick="Intercom('show')">contact us</FormButton> to
					access this feature</p>
			</div>
		<div class="" v-else>
			<Alert class="!mb-4" text="This URL used for events such as start and stop stream events.">
			</Alert>
			<div class="flex items-end gap-x-3">
				<FormInput label="Webhook Name" id="name" v-model="webhook.name" placeholder="My webhook" />
				<FormInput label="Webhook URL" id="url" v-model="webhook.url" placeholder="http://..." />
				<FormButton @click="saveWebhookUrl" :disabled="!webhook.name || !webhook.url || !edited" label="Save" />
				<FormButton type="secondary" @click="toggleWebhookUrl"  v-if="userWebhook.name" :label="userWebhook.enabled ? 'Disable': 'Enable'" />
				<FormButton type="link" @click="deleteWebhookUrl"  v-if="userWebhook.name" isIcon>
					<icon-base>
						<icon-cross />
					</icon-base>
				</FormButton>
			</div>
			<div class="inline-block !mt-6">
				<ejs-tooltip v-if="userWebhook.name" :content="tooltipMessage">
				<FormButton type="secondary" disabled class="">
					New webhook
				</FormButton>
			</ejs-tooltip>
			</div>
			<confirm-modal modal-id="modal-confirm-1" :message="deleteTitle"
				body="The link will be removed and events won't be sent anymore." ok-text="Remove" cancel-text="Cancel"
				@modal-confirm="onDeleteWebhookUrl" />
		</div>
	</div>
</template>
